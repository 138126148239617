import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WmButton } from "@watermarkinsights/ripple-react";
import { SideFlyout } from "../../common/component/SideFlyout";
import { useIntl } from "react-intl";
export const StudentFlyoutFooter = ({ handleNextClick, handlePrevClick, showError, countStudentSelection, }) => {
    const primaryButtonText = useIntl().formatMessage({
        id: "app.cohort.export.flyout.content.requirement.back",
    });
    const secondaryButtonText = useIntl().formatMessage({
        id: "app.cohort.export.flyout.content.requirement.next",
    });
    const studentSelectionCountText = useIntl().formatMessage({
        id: "app.cohort.export.flyout.student.content.student.selected.count",
    }, { count: countStudentSelection !== null && countStudentSelection !== void 0 ? countStudentSelection : 0 });
    return (_jsxs(SideFlyout.Footer, { children: [_jsx("div", Object.assign({ className: "info-section" }, { children: _jsx("span", Object.assign({ className: `count-text ${showError ? "error" : ""}`, tabIndex: 0 }, { children: studentSelectionCountText })) })), _jsxs("div", Object.assign({ className: "button-container" }, { children: [_jsx(WmButton, Object.assign({ buttonType: "secondary", id: "back-btn", onClick: handlePrevClick, placeholder: undefined, onPointerEnterCapture: undefined, onPointerLeaveCapture: undefined }, { children: primaryButtonText })), _jsx(WmButton, Object.assign({ buttonType: "primary", id: "student-next-btn", onClick: handleNextClick, placeholder: undefined, onPointerEnterCapture: undefined, onPointerLeaveCapture: undefined }, { children: secondaryButtonText }))] }))] }));
};
