import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { WmButton } from "@watermarkinsights/ripple-react";
import { useIntl } from "react-intl";
import { SideFlyout } from "../../../features/common/component/SideFlyout";
import { StudentFlyoutContent } from "./StudentFlyoutContent";
import { StudentFlyoutFooter } from "./StudentFlyoutFooter";
import { ExportSettingsFlyout } from "../ExportReportFlyout/ExportSettingsFlyout";
export const StudentFlyout = ({ currentCohort, timelineOverview, selectedUsersState, searchStudent, openStudent, openRequirement, exportType, exportSettings, countRequirementSelection, allRequirementSubmissions, closeFlyout, handlePrevClick, setExportType, setExportSettings, setOpenStudent, setOpenRequirement, setSelectedUsersState, setSearchStudent, }) => {
    const modalSubHeader = useIntl().formatMessage({
        id: "app.cohort.export.flyout.subheader",
    });
    const prefixRequiredText = useIntl().formatMessage({
        id: "app.cohort.requiredText.prefix",
    });
    const suffixRequiredText = useIntl().formatMessage({
        id: "app.cohort.requiredText.suffix",
    });
    const modalInfoText = useIntl().formatMessage({
        id: "app.cohort.export.flyout.content.student.infoText",
    });
    const breadCrumbPrimaryText = useIntl().formatMessage({
        id: "app.cohort.export.flyout.student.breadcrumb.select.requirement",
    });
    const breadCrumbSecondaryText = useIntl().formatMessage({
        id: "app.cohort.export.flyout.student.breadcrumb.select.students",
    });
    const [renderFilteredStudents, setRenderFilteredStudents] = useState(() => sortUsers(currentCohort.users));
    const [showError, setShowError] = useState(false);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [nextStep, setNextStep] = useState(false);
    function sortUsers(users) {
        return [...users].sort((a, b) => {
            const firstUserDetails = a.firstName + a.lastName;
            const secondUserDetails = b.firstName + b.lastName;
            return firstUserDetails.localeCompare(secondUserDetails);
        });
    }
    const handleSearchTextChange = (searchText) => {
        setSearchStudent(searchText);
        const filteredUsers = currentCohort.users.filter((u) => (u.firstName + " " + u.lastName)
            .toLowerCase()
            .indexOf(searchText.toLowerCase()) > -1 ||
            u.email.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
        const sortedAndFilteredUsers = sortUsers(filteredUsers);
        setRenderFilteredStudents(sortedAndFilteredUsers.length ? sortedAndFilteredUsers : null);
    };
    const handleAllSelectionChange = (value) => {
        renderFilteredStudents === null || renderFilteredStudents === void 0 ? void 0 : renderFilteredStudents.forEach((u) => {
            const index = selectedUsersState.indexOf(u.uuid);
            if (index > -1 && !value) {
                selectedUsersState.splice(index, 1);
            }
            else if (index < 0 && value) {
                selectedUsersState.push(u.uuid);
            }
        });
        setSelectedUsersState([...selectedUsersState]);
    };
    const handleChange = (uuid, checked) => {
        setShowError(false);
        if (checked) {
            setSelectedUsersState((prevState) => [...prevState, uuid]);
        }
        else {
            setSelectedUsersState((prevState) => {
                prevState = prevState.filter((id) => id != uuid);
                return prevState;
            });
        }
    };
    const handleNextClick = () => {
        if (selectedUsersState.length > 0) {
            setShowError(false);
            setNextStep(true);
        }
        else {
            setShowError(true);
        }
    };
    useEffect(() => {
        if (renderFilteredStudents) {
            const isAllSelected = renderFilteredStudents.every((user) => selectedUsersState.includes(user.uuid));
            setIsAllSelected(isAllSelected);
        }
    }, [selectedUsersState, renderFilteredStudents]);
    const renderBreadCrumb = () => {
        return (_jsxs("div", Object.assign({ className: "breadCrumb-container" }, { children: [_jsx("div", { children: _jsx(WmButton, Object.assign({ buttonType: "textonly", id: "select-requirement-breadcrumb", onClick: handlePrevClick, placeholder: undefined, onPointerEnterCapture: undefined, onPointerLeaveCapture: undefined }, { children: breadCrumbPrimaryText })) }), _jsx("div", { children: ">" }), _jsx("div", Object.assign({ className: "secondary-text" }, { children: breadCrumbSecondaryText }))] })));
    };
    return nextStep ? (_jsx(ExportSettingsFlyout, { currentCohort: currentCohort, timelineOverview: timelineOverview, selectedStudentsUuid: selectedUsersState, openStudent: openStudent, openRequirement: openRequirement, countRequirementSelection: countRequirementSelection, exportType: exportType, exportSettings: exportSettings, setExportType: setExportType, setExportSettings: setExportSettings, setOpenStudent: setOpenStudent, setOpenRequirement: setOpenRequirement, closeFlyout: closeFlyout, handlePrevClick: () => {
            setNextStep(false);
        }, redirectToStepOne: handlePrevClick, allRequirementSubmissions: allRequirementSubmissions })) : (_jsxs(SideFlyout, Object.assign({ flyoutId: "student-flyout", focusAfterClosed: "export-btn", ariaLabel: "Select student" }, { children: [_jsx(SideFlyout.Header, { modalHeader: currentCohort.title + ": " + timelineOverview.title, modalSubheader: modalSubHeader, modalRequiredPrefix: prefixRequiredText, modalRequiredSuffix: suffixRequiredText, modalInfoText: modalInfoText, breadCrumb: renderBreadCrumb(), closeFlyout: closeFlyout }), _jsx(StudentFlyoutContent, { searchText: searchStudent, showError: showError, isAllSelected: isAllSelected, selectedUsersState: selectedUsersState, renderFilteredStudents: renderFilteredStudents, handleSearchTextChange: handleSearchTextChange, handleAllSelectionChange: handleAllSelectionChange, handleChange: handleChange }), _jsx(StudentFlyoutFooter, { showError: showError, countStudentSelection: selectedUsersState.length, handleNextClick: handleNextClick, handlePrevClick: handlePrevClick })] })));
};
