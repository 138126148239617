export const getInitialExpandDetails = (timelineData) => {
    var _a;
    const expandDetails = {};
    (_a = timelineData.sections) === null || _a === void 0 ? void 0 : _a.forEach((section) => {
        const hasRequirmentsOrSubsections = (Array.isArray(section.requirements) &&
            section.requirements.length > 0) ||
            (Array.isArray(section.subSections) && section.subSections.length > 0);
        expandDetails[section.uuid] = {
            isExpandable: hasRequirmentsOrSubsections,
            isExpanded: hasRequirmentsOrSubsections ? false : true,
            isExpandAll: false,
            children: [
                ...section.requirements.map((req) => req.uuid),
                ...section.subSections.map((subSec) => subSec.uuid),
            ],
            parent: null,
        };
        // requirements
        section.requirements.forEach((req) => {
            expandDetails[req.uuid] = {
                isExpandable: true,
                isExpanded: false,
                children: [],
                parent: section.uuid,
            };
        });
        //subsections
        section.subSections.forEach((subSection) => {
            const hasRequirments = Array.isArray(subSection.requirements) &&
                subSection.requirements.length > 0;
            expandDetails[subSection.uuid] = {
                isExpandable: hasRequirments,
                isExpanded: hasRequirments ? false : true,
                isExpandAll: false,
                children: subSection.requirements.map((req) => req.uuid),
                parent: section.uuid,
            };
            //subsection requirements
            subSection.requirements.forEach((subSecReq) => {
                expandDetails[subSecReq.uuid] = {
                    isExpandable: true,
                    isExpanded: false,
                    children: [],
                    parent: subSection.uuid,
                };
            });
        });
    });
    return expandDetails;
};
