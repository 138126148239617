import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useIntl } from "react-intl";
import { WmButton } from "@watermarkinsights/ripple-react";
import { SideFlyout } from "../../common/component/SideFlyout";
export const ExportSettingsFlyoutContent = (props) => {
    const { currentCohort, selectedStudentsUuid, timelineOverview, openStudent, openRequirement, countRequirementSelection, exportType, exportSettings, setExportType, setExportSettings, redirectToStepOne, setOpenStudent, handlePrevClick, setOpenRequirement, } = props;
    const exportFormatText = useIntl().formatMessage({
        id: "app.plan.insights.export.format.text",
    });
    const exportSettingsText = useIntl().formatMessage({
        id: "app.plan.insights.export.settings.text",
    });
    const individualReqCheckbox = useIntl().formatMessage({
        id: "app.plan.insights.export.settings.individual.req.checkbox",
    });
    const includeZipCheckbox = useIntl().formatMessage({
        id: "app.plan.insights.export.settings.include.zip.checkbox",
    });
    const studentCountText = useIntl().formatMessage({
        id: "app.plan.insights.export.settings.selected.student.count",
    }, {
        count: selectedStudentsUuid.length,
    });
    const requirementCountText = useIntl().formatMessage({
        id: "app.plan.insights.export.settings.selected.student.count",
    }, {
        count: countRequirementSelection,
    });
    const includedStudents = useIntl().formatMessage({
        id: "app.plan.insights.export.settings.included.students.text",
    });
    const includedRequirements = useIntl().formatMessage({
        id: "app.plan.insights.export.settings.included.requirements.text",
    });
    const updateStudentText = useIntl().formatMessage({
        id: "app.plan.insights.export.settings.update.students.text",
    });
    const updateRequirementsText = useIntl().formatMessage({
        id: "app.plan.insights.export.settings.update.requirements.text",
    });
    const handleTypeSelection = (type) => {
        setExportType(type);
    };
    const handleSettingSelection = (val, checked) => {
        if (checked) {
            setExportSettings((prev) => [...prev, val]);
        }
        else {
            setExportSettings((prevState) => prevState.filter((val1) => val1 != val));
        }
    };
    const renderExportFormatSection = () => {
        return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "export-format-section" }, { children: [_jsx("span", Object.assign({ className: "export-format-text" }, { children: exportFormatText })), _jsx("span", Object.assign({ className: "required-text" }, { children: "*" }))] })), _jsxs("div", Object.assign({ className: "radio-options" }, { children: [_jsxs("div", Object.assign({ className: "option" }, { children: [_jsx("input", { id: "csv", className: "radio-input", type: "radio", name: "export-type-selection", checked: "CSV" == exportType, onChange: () => handleTypeSelection("CSV") }), _jsx("label", Object.assign({ className: "section-title radio", htmlFor: "csv" }, { children: "CSV" }))] })), _jsxs("div", Object.assign({ className: "option" }, { children: [_jsx("input", { id: "pdf", className: "radio-input", type: "radio", name: "export-type-selection", checked: "PDF" == exportType, onChange: () => handleTypeSelection("PDF") }), _jsx("label", Object.assign({ className: "section-title radio", htmlFor: "pdf" }, { children: "PDF" }))] }))] }))] }));
    };
    const renderExportSettingsSection = () => {
        return (_jsxs("div", Object.assign({ className: "export-settings-section" }, { children: [_jsx("span", Object.assign({ className: "export-settings-text" }, { children: exportSettingsText })), _jsxs("div", Object.assign({ className: "export-settings-options" }, { children: [exportType == "PDF" && (_jsxs("div", Object.assign({ className: "checkbox-options" }, { children: [_jsx("input", { id: "chbx-include-individual-req", className: "chkbx-input", type: "checkbox", name: "export-setting-selection", checked: exportSettings.includes("INCLUDE_REQUIREMENT"), onChange: (event) => {
                                        handleSettingSelection("INCLUDE_REQUIREMENT", event.target.checked);
                                    } }), _jsx("label", Object.assign({ className: "section-title chkbx", htmlFor: "chbx-include-individual-req" }, { children: individualReqCheckbox }))] }))), exportType == "CSV" && (_jsxs("div", Object.assign({ className: "checkbox-options" }, { children: [_jsx("input", { id: "chbx-include-zip", className: "chkbx-input", type: "checkbox", name: "export-setting-selection", checked: exportSettings.includes("INCLUDE_ZIP"), onChange: (event) => {
                                        handleSettingSelection("INCLUDE_ZIP", event.target.checked);
                                    } }), _jsx("label", Object.assign({ className: "section-title chkbx", htmlFor: "chbx-include-zip" }, { children: includeZipCheckbox }))] })))] }))] })));
    };
    const checkSectionChildSelection = (data) => {
        const isSomeReqSelected = data.requirements.some((req) => req.isSelected);
        if (isSomeReqSelected) {
            return true;
        }
        if (data.subSections) {
            const isSomeSubSectionSelected = data.subSections.some((subSection) => {
                const isSomeReqSelected = subSection.requirements.some((req) => req.isSelected);
                return isSomeReqSelected;
            });
            return isSomeSubSectionSelected;
        }
        return false;
    };
    return (_jsxs(SideFlyout.Content, { children: [renderExportFormatSection(), renderExportSettingsSection(), _jsxs("div", Object.assign({ className: "accordion-container" }, { children: [_jsxs("div", Object.assign({ className: "accordion-header" }, { children: [_jsxs("div", Object.assign({ className: "left-container" }, { children: [_jsx(WmButton, Object.assign({ buttonType: "textonly", icon: "f40a", iconRotate: openRequirement ? 90 : 0, id: "selected-requirement-toggle-btn", placeholder: "", onPointerEnterCapture: undefined, onPointerLeaveCapture: undefined, onClick: () => {
                                            setOpenRequirement(!openRequirement);
                                        } }, { children: _jsx("span", Object.assign({ className: "accordion-label" }, { children: includedRequirements })) })), _jsx("div", Object.assign({ className: "count-text" }, { children: requirementCountText }))] })), _jsx("div", Object.assign({ className: "update-student-container" }, { children: _jsx(WmButton, Object.assign({ buttonType: "textonly", id: "update-selected-students-btn", className: "update-student-btn", onClick: redirectToStepOne, placeholder: "", onPointerEnterCapture: undefined, onPointerLeaveCapture: undefined }, { children: updateRequirementsText })) }))] })), openRequirement && (_jsx("ul", Object.assign({ className: "accordion-content" }, { children: timelineOverview.sections.map((section) => {
                            const isChildSelected = checkSectionChildSelection(section);
                            if (isChildSelected) {
                                return (_jsxs("li", Object.assign({ className: "section-container" }, { children: [_jsx("span", Object.assign({ className: "section-name" }, { children: `${section.title}:` })), _jsx("ul", Object.assign({ className: "section-req-container" }, { children: section.requirements.map((req) => {
                                                if (req.isSelected) {
                                                    return (_jsx("li", Object.assign({ className: "req-name" }, { children: req.title }), req.uuid));
                                                }
                                                return null;
                                            }) })), _jsx("ul", Object.assign({ className: "subsection-container" }, { children: section.subSections.map((subSection) => {
                                                const isChildSelected = checkSectionChildSelection(subSection);
                                                if (isChildSelected) {
                                                    return (_jsxs("li", Object.assign({ className: "subsection-container-item" }, { children: [_jsx("span", Object.assign({ className: "subsection-name" }, { children: `${subSection.title}:` })), _jsx("ul", Object.assign({ className: "subsection-req-container" }, { children: subSection.requirements.map((req) => {
                                                                    if (req.isSelected) {
                                                                        return (_jsx("li", Object.assign({ className: "req-name" }, { children: req.title })));
                                                                    }
                                                                    return null;
                                                                }) }))] }), subSection.uuid));
                                                }
                                                return null;
                                            }) }))] }), section.uuid));
                            }
                            return null;
                        }) })))] })), _jsxs("div", Object.assign({ className: "accordion-container" }, { children: [_jsxs("div", Object.assign({ className: "accordion-header" }, { children: [_jsxs("div", Object.assign({ className: "left-container" }, { children: [_jsx(WmButton, Object.assign({ buttonType: "textonly", icon: "f40a", iconRotate: openStudent ? 90 : 0, id: "selected-student-toggle-btn", placeholder: "", onPointerEnterCapture: undefined, onPointerLeaveCapture: undefined, onClick: () => {
                                            setOpenStudent(!openStudent);
                                        } }, { children: _jsx("span", Object.assign({ className: "accordion-label" }, { children: includedStudents })) })), _jsx("div", Object.assign({ className: "count-text" }, { children: studentCountText }))] })), _jsx("div", Object.assign({ className: "update-student-container" }, { children: _jsx(WmButton, Object.assign({ buttonType: "textonly", id: "update-selected-students-btn", className: "update-student-btn", onClick: handlePrevClick, placeholder: "", onPointerEnterCapture: undefined, onPointerLeaveCapture: undefined }, { children: updateStudentText })) }))] })), openStudent && (_jsx("ul", Object.assign({ className: "accordion-content" }, { children: currentCohort.users.map((user) => {
                            return (selectedStudentsUuid.includes(user.uuid) && (_jsxs("li", Object.assign({ className: "item-name" }, { children: [_jsx("div", Object.assign({ className: "name-text" }, { children: user.firstName + " " + user.lastName })), _jsx("div", { children: user.email })] }), user.uuid)));
                        }) })))] }))] }));
};
