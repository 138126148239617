import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useIntl } from "react-intl";
import translate from "../../../i18n/translate";
import { getCountByStatus, getI18nValueByKey, } from "../../common/commonActions";
import { RenderBarChartByStatus } from "../RenderBarChartByStatus";
import { requirementResultStatus } from "../data/RequirementResultStatus";
export const PdfRequirementDetailView = (props) => {
    const { flatRequirementDetails, selectedStudentsSubmissions, cohortTitle } = props;
    const requirementCount = flatRequirementDetails.reduce((acc, current) => {
        return acc + current.requirements.length;
    }, 0);
    const studentCount = [
        ...new Set(selectedStudentsSubmissions.map(({ userUuid }) => userUuid)),
    ].length;
    const statusLessThan34 = useIntl().formatMessage({
        id: "app.cohort.time.log.status.less.than.34",
    });
    const statusLessThan67 = useIntl().formatMessage({
        id: "app.cohort.time.log.status.less.than.67",
    });
    const statusLessThan100 = useIntl().formatMessage({
        id: "app.cohort.time.log.status.less.than.100",
    });
    const statusEquals100 = useIntl().formatMessage({
        id: "app.cohort.time.log.status.equals.100",
    });
    const reqDescription = useIntl().formatMessage({
        id: "app.lp.reqs.detailed.export.pdf.req.desc.text",
    });
    const reqType = useIntl().formatMessage({
        id: "app.lp.reqs.detailed.export.pdf.req.type.text",
    });
    const reqResultsOverview = useIntl().formatMessage({
        id: "app.lp.reqs.detailed.export.pdf.req.resuls.overview.text",
    });
    const studentCompletedReqText = useIntl().formatMessage({
        id: "app.lp.reqs.export.pdf.student.completed.req.text",
    });
    const reqTypeStandardText = useIntl().formatMessage({
        id: "global.standard",
    });
    const lpContext = useIntl().formatMessage({
        id: "app.lp.reqs.export.pdf.lp.context",
    });
    const lpContextText = useIntl().formatMessage({
        id: "app.lp.reqs.export.pdf.lp.context.text",
    });
    const lpHeader = useIntl().formatMessage({
        id: "app.cohort.export.flyout.content.header",
    });
    const cohortDetailheader = useIntl().formatMessage({
        id: "app.lp.reqs.export.pdf.lp.export.details",
    });
    const TIME_LOG_STATUS = {
        "0-33% Completed": {
            title: statusLessThan34,
            min: -1,
            max: 33,
        },
        "34-66% Completed": {
            title: statusLessThan67,
            min: 34,
            max: 66,
        },
        "67-99% Completed": {
            title: statusLessThan100,
            min: 67,
            max: 99,
        },
        "100% Completed": {
            title: statusEquals100,
            min: 100,
            max: 100000,
        },
    };
    const getTimeLogStatusAsPerPercent = (submission) => {
        var _a;
        let percentage = (_a = Math.round(submission.percentageCompletion)) !== null && _a !== void 0 ? _a : 0;
        switch (true) {
            case percentage < 34:
                return TIME_LOG_STATUS["0-33% Completed"];
            case percentage < 67:
                return TIME_LOG_STATUS["34-66% Completed"];
            case percentage < 100:
                return TIME_LOG_STATUS["67-99% Completed"];
            default:
                return TIME_LOG_STATUS["100% Completed"];
        }
    };
    const getClassByStatus = (status) => {
        switch (status) {
            case "AWAITING_REVIEW":
            case "67-99% Completed":
                return {
                    color: "rgb(87, 81, 149)",
                    fontSize: "12px",
                    textTransform: "uppercase",
                    fontWeight: 600,
                };
            case "MET":
            case "100% Completed":
                return {
                    color: "rgb(25 , 161, 169)",
                    fontSize: "12px",
                    textTransform: "uppercase",
                    fontWeight: 600,
                };
            case "NOT_SUBMITTED":
            case "NOT_SUBMITTED_DRAFT":
            case "34-66% Completed":
                return {
                    color: `rgb(107, 107, 107)`,
                    fontSize: "12px",
                    textTransform: "uppercase",
                    fontWeight: 600,
                };
            case "NOT_MET":
            case "0-33% Completed":
                return {
                    color: "#A33D32",
                    fontSize: "12px",
                    textTransform: "uppercase",
                    fontWeight: 600,
                };
            case "ACCEPTED":
                return {
                    color: ` rgb(17, 87, 91)`,
                    fontSize: "12px",
                    textTransform: "uppercase",
                    fontWeight: 600,
                };
            case "CONDITIONALLY_ACCEPTED":
                return {
                    color: ` rgb(46, 27, 70)`,
                    fontSize: "12px",
                    textTransform: "uppercase",
                    fontWeight: 600,
                };
            case "DENIED":
                return {
                    color: `rgb(175, 41, 46)`,
                    fontSize: "12px",
                    textTransform: "uppercase",
                    fontWeight: 600,
                };
            case "DRAFT":
                return {
                    color: `rgb(58, 58, 58)`,
                    fontSize: "12px",
                    textTransform: "uppercase",
                    fontWeight: 600,
                };
            case "APPROVED":
                return {
                    color: ` rgb(17, 87, 91)`,
                    fontSize: "12px",
                    textTransform: "uppercase",
                    fontWeight: 600,
                };
            case "PENDING":
                return {
                    color: `rgb(58, 58, 58)`,
                    fontSize: "12px",
                    textTransform: "uppercase",
                    fontWeight: 600,
                };
            case "REJECTED":
                return {
                    color: `rgb(175, 41, 46)`,
                    fontSize: "12px",
                    textTransform: "uppercase",
                    fontWeight: 600,
                };
            case "IN_PROGRESS":
                return {
                    color: `rgb(233, 231, 236)`,
                    fontSize: "12px",
                    textTransform: "uppercase",
                    fontWeight: 600,
                };
            case "MERGED":
                return {
                    color: `rgba(7, 107, 0, 1)`,
                    fontSize: "12px",
                    textTransform: "uppercase",
                    fontWeight: 600,
                };
            case "MERGED_PENDING":
                return {
                    color: `rgba(87, 81, 149, 1)`,
                    fontSize: "12px",
                    textTransform: "uppercase",
                    fontWeight: 600,
                };
            default:
                return {
                    color: `rgb(107, 107, 107)`,
                    fontSize: "12px",
                    textTransform: "uppercase",
                    fontWeight: 600,
                };
        }
    };
    const getTagStyles = (requirement, submission) => {
        if (requirement.requirementType === "TIME_LOG") {
            return getClassByStatus(getTimeLogStatusAsPerPercent(submission).title);
        }
        if (submission.status === "MET" && submission.conditionallyMet) {
            return getClassByStatus("CONDITIONALLY_ACCEPTED");
        }
        return getClassByStatus(submission.status);
    };
    return (_jsxs("div", Object.assign({ style: {
            display: "flex",
            flexDirection: "column",
            pageBreakInside: "avoid",
        } }, { children: [_jsxs("div", { children: [_jsx("div", Object.assign({ style: { fontSize: "14px", fontWeight: 500, lineHeight: "14.52px" } }, { children: lpContext })), _jsx("div", Object.assign({ style: {
                            fontSize: "12px",
                            fontWeight: 400,
                            lineHeight: "12.1px",
                            letterSpacing: "0.21px",
                            marginTop: "4px",
                        } }, { children: lpContextText }))] }), _jsxs("div", Object.assign({ style: {
                    fontSize: "12px",
                    fontWeight: 500,
                    lineHeight: "12.1px",
                    marginTop: "20px",
                } }, { children: [" ", cohortDetailheader] })), _jsxs("div", Object.assign({ style: {
                    width: "500px",
                    height: "56px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    margin: "8px 0px",
                } }, { children: [_jsx("div", Object.assign({ style: {
                            flex: 1,
                            textAlign: "left",
                            padding: "20px 20px 20px 0px",
                            borderRight: "1px solid #ccc",
                        } }, { children: _jsxs("div", Object.assign({ style: {
                                display: "flex",
                                whiteSpace: "nowrap",
                                flexDirection: "column",
                            } }, { children: [_jsx("div", Object.assign({ style: { fontSize: "18px", fontWeight: 400, lineHeight: "26px" } }, { children: cohortTitle })), _jsx("span", Object.assign({ style: {
                                        fontSize: "12px",
                                        fontWeight: 400,
                                        lineHeight: "12.1px",
                                    } }, { children: translate("app.programApplications.application.student.submissions.header.cohort") }))] })) })), _jsx("div", Object.assign({ style: {
                            flex: 1,
                            textAlign: "left",
                            padding: "20px",
                            borderRight: "1px solid #ccc",
                        } }, { children: _jsxs("div", Object.assign({ style: { display: "flex", flexDirection: "column" } }, { children: [_jsx("div", Object.assign({ style: { fontSize: "18px", fontWeight: 400, lineHeight: "26px" } }, { children: studentCount })), _jsx("span", Object.assign({ style: {
                                        fontSize: "12px",
                                        fontWeight: 400,
                                        lineHeight: "12.1px",
                                    } }, { children: translate("app.openCohort.students") }))] })) })), _jsx("div", Object.assign({ style: {
                            flex: 1,
                            textAlign: "left",
                            flexDirection: "column",
                            padding: "20px",
                        } }, { children: _jsxs("div", Object.assign({ style: { display: "flex", flexDirection: "column" } }, { children: [_jsx("div", Object.assign({ style: { fontSize: "18px", fontWeight: 400, lineHeight: "26px" } }, { children: requirementCount })), _jsx("span", Object.assign({ style: {
                                        fontSize: "12px",
                                        whiteSpace: "nowrap",
                                        fontWeight: 500,
                                        lineHeight: "12.1px",
                                    } }, { children: lpHeader }))] })) }))] })), _jsxs("div", Object.assign({ style: {
                    fontSize: "16px",
                    padding: "20px 0px 0px 0px",
                    fontWeight: 500,
                } }, { children: [" ", lpHeader] })), flatRequirementDetails.map((detail, index) => {
                var _a;
                return (_jsxs("div", Object.assign({ style: {
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                    } }, { children: [" ", _jsx("span", Object.assign({ style: {
                                fontSize: "12px",
                                fontWeight: 700,
                                gap: "8px",
                                lineHeight: "20px",
                                padding: "8px",
                                paddingLeft: detail.subSectionName ? "15px" : "inherit",
                            } }, { children: (_a = detail.subSectionName) !== null && _a !== void 0 ? _a : detail.sectionName })), detail.requirements.map((requirement, index) => {
                            return (_jsx("div", Object.assign({ style: {
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "8px",
                                    padding: "0px 0px 8px 0px",
                                } }, { children: _jsx("div", Object.assign({ style: {
                                        display: "flex",
                                        flexDirection: "column",
                                    } }, { children: _jsx("div", Object.assign({ style: {
                                            fontSize: "12px",
                                            fontWeight: 400,
                                            lineHeight: "20px",
                                            paddingLeft: detail.subSectionName ? "30px" : "15px",
                                        } }, { children: requirement.title })) })) }), requirement.uuid));
                        })] }), `${detail.sectionName}_${index}}`));
            }), flatRequirementDetails.map((detail, index) => {
                var _a;
                return ((_a = detail === null || detail === void 0 ? void 0 : detail.requirements) === null || _a === void 0 ? void 0 : _a.length) > 0 ? (_jsx("div", Object.assign({ style: {
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                        pageBreakBefore: "always",
                    } }, { children: detail.requirements.map((requirement, index) => {
                        var _a;
                        const requirementsSubmissions = selectedStudentsSubmissions.filter((submission) => submission.requirementUuid === requirement.uuid);
                        return (_jsxs("div", Object.assign({ style: {
                                display: "flex",
                                flexDirection: "column",
                                gap: "8px",
                                pageBreakBefore: "always",
                            } }, { children: [_jsxs("div", Object.assign({ style: {
                                        display: "flex",
                                        flexDirection: "column",
                                    } }, { children: [_jsx("span", Object.assign({ style: { fontSize: "14px", fontWeight: 700 } }, { children: ((_a = detail.subSectionName) !== null && _a !== void 0 ? _a : detail.sectionName) +
                                                ": " +
                                                requirement.title })), _jsx("div", Object.assign({ style: {
                                                color: "rgb(74, 74, 74)",
                                                fontSize: "14px",
                                                fontWeight: "500",
                                                marginTop: "8px",
                                            } }, { children: reqDescription })), requirement.description.trim() ? (_jsx("div", Object.assign({ style: {
                                                color: "rgb(74, 74, 74)",
                                                fontSize: "12px",
                                                fontWeight: "400",
                                                marginTop: "6px",
                                            } }, { children: requirement.description.trim() }))) : (_jsx("div", Object.assign({ style: {
                                                color: "rgb(107, 107, 107)",
                                                fontSize: "12px",
                                                fontWeight: "400",
                                                marginTop: "6px",
                                            } }, { children: translate("app.openRequirement.descriptionNone") }))), _jsx("div", Object.assign({ style: {
                                                color: "rgb(74, 74, 74)",
                                                fontSize: "14px",
                                                fontWeight: "500",
                                                marginTop: "16px",
                                            } }, { children: reqType })), _jsx("span", Object.assign({ style: {
                                                textTransform: "capitalize",
                                                fontSize: "12px",
                                                fontWeight: 400,
                                                marginTop: "6px",
                                            } }, { children: requirement.requirementType === "REQUIREMENT"
                                                ? reqTypeStandardText
                                                : requirement.requirementType === "TIME_LOG"
                                                    ? translate("app.viewTimeline.dragNdrop.timeLogText")
                                                    : requirement.requirementType.toLowerCase() }))] })), _jsx("span", Object.assign({ style: {
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        marginTop: "16px",
                                        color: "rgba(74,74,74)",
                                    } }, { children: reqResultsOverview })), _jsxs("div", Object.assign({ style: { display: "flex", gap: "30px" } }, { children: [_jsxs("div", Object.assign({ style: {
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                            } }, { children: [_jsx("span", Object.assign({ style: { fontSize: "20px", fontWeight: 400 } }, { children: `${getCountByStatus(requirementsSubmissions, "MET")}/${requirementsSubmissions.length}` })), _jsx("span", Object.assign({ style: {
                                                        width: "117px",
                                                        fontSize: "12px",
                                                        fontWeight: 400,
                                                    } }, { children: studentCompletedReqText }))] })), _jsx("div", Object.assign({ style: { pageBreakInside: "avoid" } }, { children: _jsx(RenderBarChartByStatus, { currentCohortStudents: requirementsSubmissions, currentRequirement: requirement, resetFilters: index }) }))] })), _jsx("div", { children: _jsxs("table", Object.assign({ style: {
                                            display: "grid",
                                            gridTemplateColumns: "repeat(3, 1fr)",
                                            marginTop: "16px",
                                            width: "100%",
                                            border: "1px solid rgba(74, 74, 74, 0.49)",
                                        } }, { children: [_jsx("thead", Object.assign({ style: {
                                                    display: "contents",
                                                } }, { children: _jsxs("tr", Object.assign({ style: {
                                                        display: "contents",
                                                    } }, { children: [_jsx("th", Object.assign({ style: {
                                                                padding: "8px 15px",
                                                                textTransform: "uppercase",
                                                                fontSize: "12px",
                                                                fontWeight: 700,
                                                                background: "rgba(238, 237, 244, 1)",
                                                                textAlign: "left",
                                                                borderRight: "1px solid rgba(151, 151, 151, 0.66)",
                                                            } }, { children: translate("app.openRequirement.table.header.studentName") })), _jsx("th", Object.assign({ style: {
                                                                padding: "8px 15px",
                                                                textTransform: "uppercase",
                                                                fontSize: "12px",
                                                                fontWeight: 700,
                                                                background: "rgba(238, 237, 244, 1)",
                                                                textAlign: "left",
                                                                borderRight: "1px solid rgba(151, 151, 151, 0.66)",
                                                            } }, { children: translate("app.openRequirement.table.header.studentEmail") })), _jsx("th", Object.assign({ style: {
                                                                padding: "8px 15px",
                                                                textTransform: "uppercase",
                                                                fontSize: "12px",
                                                                fontWeight: 700,
                                                                background: "rgba(238, 237, 244, 1)",
                                                                textAlign: "left",
                                                            } }, { children: translate("app.openRequirement.table.header.status") }))] })) })), _jsx("tbody", Object.assign({ style: {
                                                    display: "contents",
                                                } }, { children: requirementsSubmissions === null || requirementsSubmissions === void 0 ? void 0 : requirementsSubmissions.map((submission, index) => {
                                                    var _a;
                                                    return (_jsxs("tr", Object.assign({ style: {
                                                            display: "contents",
                                                        } }, { children: [_jsx("td", Object.assign({ style: {
                                                                    fontWeight: 600,
                                                                    fontSize: "12px",
                                                                    paddingLeft: "15px",
                                                                    paddingRight: "15px",
                                                                    paddingBottom: "5px",
                                                                    borderRight: "1px solid rgba(151, 151, 151, 0.66)",
                                                                    paddingTop: index === 0 ? "13px" : "5px",
                                                                } }, { children: submission.user.lastName +
                                                                    ", " +
                                                                    submission.user.firstName })), _jsx("td", Object.assign({ style: {
                                                                    fontWeight: 400,
                                                                    fontSize: "12px",
                                                                    paddingLeft: "15px",
                                                                    paddingRight: "15px",
                                                                    paddingBottom: "5px",
                                                                    borderRight: "1px solid rgba(151, 151, 151, 0.66)",
                                                                    paddingTop: index === 0 ? "13px" : "5px",
                                                                } }, { children: submission.user.email })), _jsx("td", Object.assign({ style: {
                                                                    display: "flex",
                                                                    paddingLeft: "15px",
                                                                    paddingRight: "15px",
                                                                    paddingBottom: "5px",
                                                                    paddingTop: index === 0 ? "13px" : "5px",
                                                                } }, { children: _jsx("span", Object.assign({ style: getTagStyles(requirement, submission) }, { children: requirement.requirementType === "TIME_LOG"
                                                                        ? (_a = getTimeLogStatusAsPerPercent(submission)) === null || _a === void 0 ? void 0 : _a.title
                                                                        : submission.status === "MET" &&
                                                                            submission.conditionallyMet
                                                                            ? "Conditional"
                                                                            : getI18nValueByKey(submission.status, requirementResultStatus) })) }))] }), submission.uuid));
                                                }) }))] })) })] }), requirement.uuid));
                    }) }), `${detail.sectionName}_${index}}`)) : null;
            })] })));
};
