import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { RenderBarChartByStatus } from "../RenderBarChartByStatus";
import { getCountByStatus } from "../../../features/common/commonActions";
import { useIntl } from "react-intl";
import translate from "../../../i18n/translate";
export const PdfRequirementView = (props) => {
    const { flatRequirementDetails, selectedStudentsSubmissions, cohortTitle } = props;
    const requirementCount = flatRequirementDetails.reduce((acc, current) => {
        return acc + current.requirements.length;
    }, 0);
    const studentCount = [
        ...new Set(selectedStudentsSubmissions.map(({ userUuid }) => userUuid)),
    ].length;
    const reqProgressText = useIntl().formatMessage({
        id: "app.lp.reqs.export.pdf.req.progress.text",
    });
    const studentCompletedReqText = useIntl().formatMessage({
        id: "app.lp.reqs.export.pdf.student.completed.req.text",
    });
    const lpContext = useIntl().formatMessage({
        id: "app.lp.reqs.export.pdf.lp.context",
    });
    const lpContextText = useIntl().formatMessage({
        id: "app.lp.reqs.export.pdf.lp.context.text",
    });
    const lpHeader = useIntl().formatMessage({
        id: "app.cohort.export.flyout.content.header",
    });
    const cohortDetailheader = useIntl().formatMessage({
        id: "app.lp.reqs.export.pdf.lp.export.details",
    });
    return (_jsxs("div", Object.assign({ style: {
            display: "flex",
            flexDirection: "column",
        } }, { children: [_jsxs("div", { children: [_jsx("div", Object.assign({ style: { fontSize: "14px", fontWeight: 500, lineHeight: "14.52px" } }, { children: lpContext })), _jsx("div", Object.assign({ style: {
                            fontSize: "12px",
                            fontWeight: 400,
                            lineHeight: "12.1px",
                            letterSpacing: "0.21px",
                            marginTop: "4px",
                        } }, { children: lpContextText }))] }), _jsxs("div", Object.assign({ style: {
                    fontSize: "12px",
                    fontWeight: 500,
                    lineHeight: "12.1px",
                    marginTop: "20px",
                } }, { children: [" ", cohortDetailheader] })), _jsxs("div", Object.assign({ style: {
                    width: "500px",
                    height: "56px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    margin: "8px 0px",
                } }, { children: [_jsx("div", Object.assign({ style: {
                            flex: 1,
                            textAlign: "left",
                            padding: "20px 20px 20px 0px",
                            borderRight: "1px solid #ccc",
                        } }, { children: _jsxs("div", Object.assign({ style: {
                                display: "flex",
                                whiteSpace: "nowrap",
                                flexDirection: "column",
                            } }, { children: [_jsx("div", Object.assign({ style: { fontSize: "18px", fontWeight: 400, lineHeight: "26px" } }, { children: cohortTitle })), _jsx("span", Object.assign({ style: {
                                        fontSize: "12px",
                                        fontWeight: 400,
                                        lineHeight: "12.1px",
                                    } }, { children: translate("app.programApplications.application.student.submissions.header.cohort") }))] })) })), _jsx("div", Object.assign({ style: {
                            flex: 1,
                            textAlign: "left",
                            padding: "20px",
                            borderRight: "1px solid #ccc",
                        } }, { children: _jsxs("div", Object.assign({ style: { display: "flex", flexDirection: "column" } }, { children: [_jsx("div", Object.assign({ style: { fontSize: "18px", fontWeight: 400, lineHeight: "26px" } }, { children: studentCount })), _jsx("span", Object.assign({ style: {
                                        fontSize: "12px",
                                        fontWeight: 400,
                                        lineHeight: "12.1px",
                                    } }, { children: translate("app.openCohort.students") }))] })) })), _jsx("div", Object.assign({ style: {
                            flex: 1,
                            textAlign: "left",
                            flexDirection: "column",
                            padding: "20px",
                        } }, { children: _jsxs("div", Object.assign({ style: { display: "flex", flexDirection: "column" } }, { children: [_jsx("div", Object.assign({ style: { fontSize: "18px", fontWeight: 400, lineHeight: "26px" } }, { children: requirementCount })), _jsx("span", Object.assign({ style: {
                                        fontSize: "12px",
                                        whiteSpace: "nowrap",
                                        fontWeight: 500,
                                        lineHeight: "12.1px",
                                    } }, { children: lpHeader }))] })) }))] })), _jsxs("div", Object.assign({ style: {
                    fontSize: "16px",
                    padding: "20px 0px 0px 0px",
                    fontWeight: 500,
                } }, { children: [" ", lpHeader] })), flatRequirementDetails.map((detail, index) => {
                var _a;
                return (_jsxs("div", Object.assign({ style: {
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                    } }, { children: [" ", _jsx("span", Object.assign({ style: {
                                fontSize: "12px",
                                fontWeight: 700,
                                gap: "8px",
                                lineHeight: "20px",
                                padding: "8px",
                                paddingLeft: detail.subSectionName ? "15px" : "inherit",
                            } }, { children: (_a = detail.subSectionName) !== null && _a !== void 0 ? _a : detail.sectionName })), detail.requirements.map((requirement, index) => {
                            return (_jsx("div", Object.assign({ style: {
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "8px",
                                    padding: "0px 0px 8px 0px",
                                } }, { children: _jsx("div", Object.assign({ style: {
                                        display: "flex",
                                        flexDirection: "column",
                                    } }, { children: _jsx("div", Object.assign({ style: {
                                            fontSize: "12px",
                                            fontWeight: 400,
                                            lineHeight: "20px",
                                            paddingLeft: detail.subSectionName ? "30px" : "15px",
                                        } }, { children: requirement.title })) })) }), requirement.uuid));
                        })] }), `${detail.sectionName}_${index}}`));
            }), _jsxs("div", Object.assign({ style: { pageBreakInside: "avoid" } }, { children: [_jsx("span", Object.assign({ style: { fontSize: "14px", fontWeight: 500, gap: "20px" } }, { children: `${cohortTitle}'s ${reqProgressText}` })), flatRequirementDetails.map((detail, index) => {
                        var _a;
                        return (_jsxs("div", Object.assign({ style: {
                                display: "flex",
                                flexDirection: "column",
                                gap: "16px",
                                marginTop: "8px",
                            } }, { children: [_jsx("span", Object.assign({ style: {
                                        fontWeight: 700,
                                        fontSize: "14px",
                                    } }, { children: (_a = detail.subSectionName) !== null && _a !== void 0 ? _a : detail.sectionName })), _jsx("div", Object.assign({ style: {
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "40px",
                                    } }, { children: detail.requirements.map((requirement, index) => {
                                        var _a;
                                        const allRequirementSubmissions = selectedStudentsSubmissions.filter((submission) => submission.requirementUuid === requirement.uuid);
                                        return (_jsxs("div", Object.assign({ style: {
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "8px",
                                                pageBreakInside: "avoid",
                                            } }, { children: [_jsxs("div", Object.assign({ style: { display: "flex", flexDirection: "column" } }, { children: [_jsx("span", Object.assign({ style: { fontSize: "12px", fontWeight: 400 } }, { children: (_a = detail.subSectionName) !== null && _a !== void 0 ? _a : detail.sectionName })), _jsx("span", Object.assign({ style: { fontSize: "16px", fontWeight: 500 } }, { children: requirement.title }))] })), _jsxs("div", Object.assign({ style: { display: "flex", gap: "30px" } }, { children: [_jsxs("div", Object.assign({ style: {
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "center",
                                                            } }, { children: [_jsx("span", Object.assign({ style: { fontSize: "20px", fontWeight: 400 } }, { children: `${getCountByStatus(allRequirementSubmissions, "MET")}/${allRequirementSubmissions.length}` })), _jsx("span", Object.assign({ style: {
                                                                        width: "117px",
                                                                        fontSize: "12px",
                                                                        fontWeight: 400,
                                                                    } }, { children: studentCompletedReqText }))] })), _jsx("div", Object.assign({ style: { pageBreakInside: "avoid" } }, { children: _jsx(RenderBarChartByStatus, { currentCohortStudents: allRequirementSubmissions, currentRequirement: requirement, resetFilters: index }) }))] }))] }), requirement.uuid));
                                    }) }))] }), `${detail.sectionName}_${index}}`));
                    })] }))] })));
};
