import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { SideFlyout } from "../../../features/common/component/SideFlyout";
import { useIntl } from "react-intl";
import { RequirementFlyoutContent } from "./RequirementFlyoutContent";
import { RequirementFlyoutFooter } from "./RequirementFlyoutFooter";
import { useEffect, useMemo, useState } from "react";
import { StudentFlyout } from "../ExportStudentFlyout/StudentFlyout";
export const RequirementFlyout = ({ closeFlyout, timelineOverview, currentCohort, setTimelineOverview, allRequirementSubmissions, }) => {
    const requirementModalSubHeader = useIntl().formatMessage({
        id: "app.cohort.export.flyout.subheader",
    });
    const prefixRequiredText = useIntl().formatMessage({
        id: "app.cohort.requiredText.prefix",
    });
    const suffixRequiredText = useIntl().formatMessage({
        id: "app.cohort.requiredText.suffix",
    });
    const modalInfoText = useIntl().formatMessage({
        id: "app.cohort.export.flyout.content.requirement.infoText",
    });
    const [renderFilteredRequirements, setRenderFilteredRequirements] = useState(!!timelineOverview ? timelineOverview : null);
    const [searchText, setSearchText] = useState("");
    const [showError, setShowError] = useState(false);
    const [nextStep, setNextStep] = useState(false);
    const [selectedUsersState, setSelectedUsersState] = useState([]);
    const [searchStudent, setSearchStudent] = useState("");
    const [openStudent, setOpenStudent] = useState(false);
    const [openRequirement, setOpenRequirement] = useState(false);
    const [exportType, setExportType] = useState("CSV");
    const [exportSettings, setExportSettings] = useState([]);
    const handleSearchTextChange = (text) => {
        setSearchText(text);
    };
    const handleAllSelectionChange = (value) => {
        setShowError(false);
        if (renderFilteredRequirements) {
            const allUuids = [];
            renderFilteredRequirements.sections.forEach((section) => {
                allUuids.push(section.uuid);
                section.requirements.forEach((requirement) => allUuids.push(requirement.uuid));
                section.subSections.forEach((subSection) => {
                    allUuids.push(subSection.uuid);
                    subSection.requirements.forEach((req) => allUuids.push(req.uuid));
                });
            });
            timelineOverview.isSelected = value;
            timelineOverview.sections.forEach((section) => {
                if (allUuids.includes(section.uuid)) {
                    section.isSelected = value;
                }
                section.requirements.forEach((requirement) => {
                    if (allUuids.includes(requirement.uuid)) {
                        requirement.isSelected = value;
                    }
                });
                section.subSections.forEach((subSection) => {
                    if (allUuids.includes(subSection.uuid)) {
                        subSection.isSelected = value;
                    }
                    subSection.requirements.forEach((req) => {
                        if (allUuids.includes(req.uuid)) {
                            req.isSelected = value;
                        }
                    });
                });
            });
            setTimelineOverview(Object.assign({}, timelineOverview));
        }
    };
    const selectRequirements = (requirements, checked) => {
        requirements.forEach((requirement) => {
            requirement.isSelected = checked;
        });
    };
    const selectSubSection = (subSection, checked) => {
        subSection.isSelected = checked;
        selectRequirements(subSection.requirements, checked);
    };
    const selectSection = (section, checked) => {
        section.isSelected = checked;
        section.subSections.forEach((subSection) => {
            selectSubSection(subSection, checked);
        });
        selectRequirements(section.requirements, checked);
    };
    const selectAllTimelines = (timeline, checked) => {
        timeline.isSelected = checked;
        timeline.sections.forEach((section) => {
            selectSection(section, checked);
        });
        return timeline;
    };
    const handleChange = (uuid, checked) => {
        setShowError(false);
        let updatedTimelineOverview = Object.assign({}, timelineOverview);
        if (updatedTimelineOverview.uuid == uuid) {
            updatedTimelineOverview = selectAllTimelines(updatedTimelineOverview, checked);
        }
        else {
            updatedTimelineOverview.sections.forEach((section) => {
                if (section.uuid == uuid) {
                    selectSection(section, checked);
                }
                else {
                    section.requirements.forEach((requirement) => {
                        if (requirement.uuid == uuid) {
                            requirement.isSelected = checked;
                        }
                    });
                    section.subSections.forEach((subSection) => {
                        if (subSection.uuid == uuid) {
                            selectSubSection(subSection, checked);
                        }
                        else {
                            subSection.requirements.forEach((requirement) => {
                                if (requirement.uuid == uuid) {
                                    requirement.isSelected = checked;
                                }
                            });
                            if (subSection.requirements.length) {
                                subSection.isSelected = subSection.requirements.every((requirement) => requirement.isSelected);
                            }
                        }
                    });
                    if (section.requirements.length || section.subSections.length) {
                        section.isSelected =
                            section.requirements.every((requirement) => requirement.isSelected) &&
                                section.subSections.every((subSection) => subSection.isSelected);
                    }
                }
            });
        }
        setTimelineOverview(updatedTimelineOverview);
    };
    const filterTimelineOverview = (timelineOverview, searchText) => {
        if (!timelineOverview) {
            return null;
        }
        const filteredSections = timelineOverview.sections.reduce((newSections, section) => {
            if (section.title.toLowerCase().includes(searchText.toLowerCase())) {
                newSections.push(section);
                return newSections;
            }
            const newRequirements = section.requirements.filter((requirement) => requirement.title.toLowerCase().includes(searchText.toLowerCase()));
            const newSubsection = section.subSections.reduce((newSubsection, subSection) => {
                if (subSection.title.toLowerCase().includes(searchText.toLowerCase())) {
                    newSubsection.push(subSection);
                    return newSubsection;
                }
                const newSubSectionRequirements = subSection.requirements.filter((requirement) => requirement.title
                    .toLowerCase()
                    .includes(searchText.toLowerCase()));
                if (!newSubSectionRequirements.length) {
                    return newSubsection;
                }
                newSubsection.push(Object.assign(Object.assign({}, subSection), { requirements: newSubSectionRequirements }));
                return newSubsection;
            }, []);
            if (!newRequirements.length && !newSubsection.length) {
                return newSections;
            }
            newSections.push(Object.assign(Object.assign({}, section), { requirements: newRequirements, subSections: newSubsection }));
            return newSections;
        }, []);
        if (filteredSections.length === 0) {
            return null;
        }
        return Object.assign(Object.assign({}, timelineOverview), { sections: filteredSections, isSelected: filteredSections.every((sec) => sec.isSelected) });
    };
    const closeFlyoutHandler = () => {
        // reset timeline data
        timelineOverview.isSelected = false;
        timelineOverview.sections.forEach((section) => {
            section.isSelected = false;
            section.requirements.forEach((requirement) => {
                requirement.isSelected = false;
            });
            section.subSections.forEach((subSection) => {
                subSection.isSelected = false;
                subSection.requirements.forEach((req) => {
                    req.isSelected = false;
                });
            });
        });
        setTimelineOverview(Object.assign({}, timelineOverview));
        closeFlyout();
    };
    const countRequirementSelection = useMemo(() => {
        let count = 0;
        timelineOverview.sections.forEach((section) => {
            section.requirements.forEach((requirement) => {
                if (requirement.isSelected) {
                    count += 1;
                }
            });
            section.subSections.forEach((subSection) => {
                subSection.requirements.forEach((requirement) => {
                    if (requirement.isSelected) {
                        count += 1;
                    }
                });
            });
        });
        return count;
    }, [timelineOverview]);
    const handleNextClick = () => {
        if (timelineOverview.sections.some((section) => {
            return (section.requirements.some((requirement) => requirement.isSelected) ||
                section.subSections.some((subSection) => {
                    return subSection.requirements.some((requirement) => requirement.isSelected);
                }));
        })) {
            setShowError(false);
            setNextStep(true);
        }
        else {
            setShowError(true);
        }
    };
    useEffect(() => {
        const filteredTimelineOverview = filterTimelineOverview(timelineOverview, searchText);
        setRenderFilteredRequirements(filteredTimelineOverview);
    }, [timelineOverview, searchText]);
    return (_jsx(_Fragment, { children: nextStep ? (_jsx(StudentFlyout, { closeFlyout: closeFlyoutHandler, timelineOverview: timelineOverview, currentCohort: currentCohort, selectedUsersState: selectedUsersState, searchStudent: searchStudent, countRequirementSelection: countRequirementSelection, setSelectedUsersState: setSelectedUsersState, setSearchStudent: setSearchStudent, openStudent: openStudent, openRequirement: openRequirement, exportType: exportType, exportSettings: exportSettings, setExportType: setExportType, setExportSettings: setExportSettings, setOpenStudent: setOpenStudent, setOpenRequirement: setOpenRequirement, handlePrevClick: () => {
                setNextStep(false);
            }, allRequirementSubmissions: allRequirementSubmissions })) : (_jsxs(SideFlyout, Object.assign({ flyoutId: "requirement-flyout", focusAfterClosed: "export-btn", ariaLabel: "Select requirement" }, { children: [_jsx(SideFlyout.Header, { modalHeader: currentCohort.title + ": " + timelineOverview.title, modalSubheader: requirementModalSubHeader, modalRequiredPrefix: prefixRequiredText, modalRequiredSuffix: suffixRequiredText, closeFlyout: closeFlyoutHandler, modalInfoText: modalInfoText }), _jsx(RequirementFlyoutContent, { searchText: searchText, showError: showError, renderFilteredRequirements: renderFilteredRequirements, handleSearchTextChange: handleSearchTextChange, handleAllSelectionChange: handleAllSelectionChange, handleChange: handleChange }), _jsx(RequirementFlyoutFooter, { closeFlyout: closeFlyoutHandler, showError: showError, handleNextClick: handleNextClick, countRequirementSelection: countRequirementSelection })] }))) }));
};
