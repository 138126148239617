import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WmButton } from "@watermarkinsights/ripple-react";
import { SideFlyout } from "../../common/component/SideFlyout";
import { useIntl } from "react-intl";
export const ExportSettingsFlyoutFooter = ({ startExportHandler, handlePrevClick, }) => {
    const primaryButtonText = useIntl().formatMessage({
        id: "app.cohort.export.flyout.content.requirement.back",
    });
    const startExportText = useIntl().formatMessage({
        id: "app.cohort.export.flyout.content.requirement.start.export",
    });
    return (_jsx(SideFlyout.Footer, { children: _jsxs("div", Object.assign({ className: "button-container" }, { children: [_jsx(WmButton, Object.assign({ buttonType: "secondary", id: "back-btn", onClick: handlePrevClick }, { children: primaryButtonText })), _jsx(WmButton, Object.assign({ buttonType: "primary", id: "student-next-btn", icon: "f3cc", onClick: startExportHandler }, { children: startExportText }))] })) }));
};
