import { setIsExpanded, setSubIsExpanded, } from "../licensurePlans/TimelineSlice";
export const toggleHandler = (event, isExpanded, isTypeRequirement, index, dispatch, isTypeCohort) => {
    event.preventDefault();
    dispatch(setIsExpanded({ isExpanded, index, isTypeRequirement, isTypeCohort }));
};
export const subtoggleHandler = (event, isExpanded, isTypeRequirement, index, uuid, dispatch, isTypeCohort) => {
    event.preventDefault();
    dispatch(setSubIsExpanded({
        isExpanded,
        index,
        uuid,
        isTypeRequirement,
        isTypeCohort,
    }));
};
export const getI18nValueByKey = (key, sourceData) => {
    if (key) {
        const index = sourceData.findIndex((fn) => {
            return fn.key === key;
        });
        if (index === -1) {
            return "";
        }
        return sourceData[index].value_i18n;
    }
    else {
        return "";
    }
};
export const getI18nValueByTag = (key, sourceData) => {
    if (key) {
        const index = sourceData.findIndex((fn) => {
            return fn.key === key;
        });
        if (index === -1) {
            return "";
        }
        return sourceData[index].tag_i18n;
    }
    else {
        return "";
    }
};
export const getI18nValueByCohorts = (key, sourceData) => {
    if (key) {
        const index = sourceData.findIndex((fn) => {
            return fn.key === key;
        });
        if (index === -1) {
            return "";
        }
        return sourceData[index].value_cohorts_i18n;
    }
    else {
        return "";
    }
};
export const getValueByKey = (key, sourceData) => {
    if (key) {
        const index = sourceData.findIndex((fn) => {
            return fn.key === key;
        });
        if (index === -1) {
            return "";
        }
        return sourceData[index].value;
    }
    else {
        return "";
    }
};
export const getIdByKey = (key, sourceData) => {
    if (key) {
        const index = sourceData.findIndex((fn) => {
            return fn.key === key;
        });
        if (index === -1) {
            return "";
        }
        return sourceData[index].id;
    }
    else {
        return "";
    }
};
export const getCategoryByKey = (key, sourceData) => {
    if (key) {
        const index = sourceData.findIndex((fn) => {
            return fn.key === key;
        });
        if (index === -1) {
            return "";
        }
        return sourceData[index].category;
    }
    else {
        return "";
    }
};
export const getClassByStatus = (status) => {
    switch (status) {
        case "AWAITING_REVIEW":
        case "67-99% Completed":
            return "status-tag awaiting-review-tag";
        case "MET":
        case "100% Completed":
            return "status-tag met-tag";
        case "NOT_SUBMITTED":
        case "NOT_SUBMITTED_DRAFT":
        case "34-66% Completed":
            return "status-tag";
        case "NOT_MET":
        case "0-33% Completed":
            return "status-tag not-met-tag";
        case "ACCEPTED":
            return "status-tag met-tag";
        case "CONDITIONALLY_ACCEPTED":
            return "status-tag conditional-tag";
        case "DENIED":
            return "status-tag not-met-tag";
        case "DRAFT":
            return "status-tag draft-tag ";
        case "APPROVED":
            return "status-tag met-tag";
        case "PENDING":
            return "status-tag pending-tag";
        case "REJECTED":
            return "status-tag not-met-tag";
        case "IN_PROGRESS":
            return "status-tag in-progress-tag";
        case "MERGED":
            return "status-tag merged-tag";
        case "MERGED_PENDING":
            return "status-tag merged-pending-tag";
        default:
            return "";
    }
};
export const getIconClassesByStatus = (status) => {
    switch (status) {
        case "AWAITING_REVIEW":
            return "awaiting-review-icon";
        case "MET":
            return "met-icon";
        case "NOT_SUBMITTED":
            return "not-submitted-icon";
        case "NOT_SUBMITTED_DRAFT":
            return "not-submitted-icon";
        case "NOT_MET":
            return "not-met-icon";
        case "IN_PROGRESS":
            return "in-progress-icon";
        default:
            return "";
    }
};
export const getIconByType = (type) => {
    switch (type) {
        case "TEXT":
            return "mdi mdi-text-subject";
        case "SMALL_TEXT_FIELD":
            return "mdi mdi-textbox";
        case "SECTION_HEADER":
            return "mdi mdi-format-text";
        case "LINK_RESOURCE":
            return "mdi mdi-link-variant";
        case "ATTACHMENT":
            return "mdi mdi-paperclip";
        case "LARGE_TEXT_AREA":
            return "mdi mdi-text-subject";
        case "LINK_INPUT":
            return "mdi mdi-link-variant";
        case "FILE_UPLOAD":
            return "mdi mdi-upload";
        case "DROPDOWN":
            return "mdi mdi-menu-down";
        case "MULTISELECT_CHECKBOXES":
            return "mdi mdi-checkbox-multiple-marked";
        case "ACKNOWLEDGEMENT_CHECKBOX":
            return "mdi mdi-checkbox-marked";
        case "DATE_SELECTOR":
            return "mdi mdi-calendar-today";
        case "RADIO_BUTTON":
            return "mdi mdi-radiobox-marked";
        default:
            return "";
    }
};
export const getCountByStatus = (studentList, status) => {
    var _a;
    return (_a = studentList === null || studentList === void 0 ? void 0 : studentList.filter((student) => {
        if (status === "CONDITIONAL") {
            return student.status === "MET" && student.conditionallyMet === true;
        }
        else if (status === "MET") {
            return student.status === status && student.conditionallyMet === false;
        }
        return student.status === status;
    })) === null || _a === void 0 ? void 0 : _a.length;
};
export const getUploaderFiles = (comp) => {
    var _a;
    const uploaderFiles = (_a = comp.files) === null || _a === void 0 ? void 0 : _a.map((file) => {
        let uploaderFile = {
            id: file.uuid,
            name: file.file_name.substr(0, file.file_name.lastIndexOf(".")),
            type: file.file_name.substr(file.file_name.lastIndexOf(".") + 1),
            lastUpdated: file.inserted_at,
            fileActions: "",
        };
        return uploaderFile;
    });
    return uploaderFiles;
};
export const getFilesList = (files) => {
    const _files = files.map((file) => {
        return {
            id: file.uuid,
            name: file.file_name.slice(0, file.file_name.lastIndexOf(".")),
            type: file.file_name.slice(file.file_name.lastIndexOf(".") + 1),
            lastUpdated: file.inserted_at,
            fileActions: "download",
        };
    });
    return _files;
};
export const getStudentIdentifierForBackend = (obj) => {
    return obj.studentUuid ? obj.studentUuid : obj.email;
};
export const getTimeLogStatusAsPerPercent = (submission) => {
    var _a;
    const percentage = (_a = Math.round(submission.percentageCompletion)) !== null && _a !== void 0 ? _a : 0;
    switch (true) {
        case percentage < 34:
            return "0-33% Completed";
        case percentage < 67:
            return "34-66% Completed";
        case percentage < 100:
            return "67-99% Completed";
        default:
            return "100% Completed";
    }
};
export const getCountByPercentageCompletion = (list, statusKey) => {
    return list.reduce((count, requirement) => {
        const key = getTimeLogStatusAsPerPercent(requirement);
        count += statusKey === key ? 1 : 0;
        return count;
    }, 0);
};
export const getAllsectionRequirements = (section, allRequirementSubmissions) => {
    const sectionSubmissionList = [];
    section.requirements.forEach((requirement) => {
        const requirementSubmissionList = allRequirementSubmissions.filter(({ requirementUuid }) => requirementUuid === requirement.uuid);
        sectionSubmissionList.push(...requirementSubmissionList);
    });
    if (section.subSections) {
        section.subSections.forEach((subSection) => {
            sectionSubmissionList.push(...getAllsectionRequirements(subSection, allRequirementSubmissions));
        });
    }
    return sectionSubmissionList;
};
